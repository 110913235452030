<section class="vh-100">
    <div class="container-fluid px-0">
        <div class="row mx-0">
            <div class="col-md-6 sign-bg vh-100">
                <div class="bg-content">
                    <H1>Ads Tracker</H1>
                    <h3></h3>
                </div>
            </div>
            <div class="col-md-6">
                <div class="sign-form">
                    <!-- sign Form Section-->
                    <div class="container form-section">
                        <router-outlet></router-outlet>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>