import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  private storageSub = new Subject<any>();

  constructor(private router: Router) { }

  watchStorage(): Observable<any> {
    return this.storageSub.asObservable();
  }

  setItem(key: string, data: any) {
    localStorage.setItem(key, data);
    this.storageSub.next({ key, data });
  }

  getItem(key: string) {
    let item = localStorage.getItem(key);
    return item ? item : null;
  }

  removeItem(key: string) {
    localStorage.removeItem(key);
    this.storageSub.next({ key, data: null });
  }

  isKeyExistInLocal(key: string) {
    let item = localStorage.getItem(key);
    return item ? true : false;
  }
}
