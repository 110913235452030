import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { Router } from '@angular/router';
import { LOCAL_STORAGE_KEY } from '@utils/constants';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(
    private localStorageService: LocalStorageService,
    private router: Router
  ) { }

  public isAuthenticated(): boolean {
    let token: any = this.localStorageService.getItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN);
    if (token) {
      return true;
    }
    return false;
  }

  getUserType() {
    return this.localStorageService.getItem(LOCAL_STORAGE_KEY.USER_TYPE);
  }

  getUserDetails() {
    const user = this.localStorageService.getItem(LOCAL_STORAGE_KEY.USER_DETAILS);
    if (user) {
      return JSON.parse(user);
    }
    return null;
  }

  // To close user session
  doLogout() {
    this.localStorageService.removeItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN);
    this.localStorageService.removeItem(LOCAL_STORAGE_KEY.USER_TYPE);
    this.localStorageService.removeItem(LOCAL_STORAGE_KEY.USER_DETAILS);
    this.router.navigate([`/auth/signin`]);
  }
}
