<mat-toolbar [color]="navBackground">
    AdsTracker
    <span style="flex: 1 1 auto;"></span>
    <div>
        <button mat-icon-button matTooltip="Notifications" (click)="loadNotificationPage()">
            <mat-icon [matBadge]="notificationsCount" [matBadgeHidden]="notificationsCount === 0">notifications
            </mat-icon>
        </button>
        <button mat-icon-button matTooltip="My Account" [matMenuTriggerFor]="accountMenu">
            <mat-icon>account_circle</mat-icon>
        </button>
        <mat-menu #accountMenu [overlapTrigger]="false" yPosition="below">
            <div>
                <h3 class="mb-1 mt-2 text-center">{{portalType}}</h3>
            </div>
            <div class="profileInfo">
                <h3 class="mb-1 mt-2 text-center">{{portalName}}</h3>
                <h5 class="mb-1 mt-2 text-center">{{userName}}</h5>
            </div>
            <mat-divider></mat-divider>
            <button mat-menu-item [routerLink]="['/panel/settings/profile']">
                <mat-icon>person</mat-icon><span>My Profile</span>
            </button>
            <mat-divider></mat-divider>
            <button mat-menu-item (click)="doLogout()">
                <mat-icon>exit_to_app</mat-icon>Logout
            </button>
        </mat-menu>
    </div>
</mat-toolbar>