<ng-template #confirmModal let-modal>
    <div class="modal-header">
        <div class="modal-confirm-header tag-status-header">
            <h3 class="mb-0">{{modalConfig.modalTitle}}</h3>
        </div>
    </div>
    <div class="modal-body">
        <div class="modal-confirm-contain modal-tag-contain">
            <p>{{modalConfig.modalMessage}}</p>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modalConfirm()">OK</button>
        <button type="button" class="btn btn-outline-dark" (click)="close()">Cancel</button>
    </div>
</ng-template>