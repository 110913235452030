<app-nav-bar></app-nav-bar>
<mat-sidenav-container style="height: calc(100% - 64px); margin: 0;">
    <mat-sidenav #sidenav mode="side" opened="true" [class.mat-elevation-z4]="true" style="width: 240px">
        <app-sidebar></app-sidebar>
    </mat-sidenav>
    <mat-sidenav-content>
        <div class=" container-fluid mat-body-1">
            <router-outlet></router-outlet>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>