import { Injectable } from '@angular/core';
import { Observable, of, EMPTY } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(
    public http: HttpClient
  ) { }

  // api call 
  public parseApiCall(url: string, method: string, data: any = {}, options: any = {}): Observable<any> {
    switch (method) {
      case 'GET':
        return this.http.get(url + "?" + new URLSearchParams(data), options).pipe(map((response) => {
          return response;
        }));
      case 'POST':
        return this.http.post(url, data).pipe(map((response) => {
          return response;
        }));
      case 'PUT':
        return this.http.put(url, data).pipe(map((response) => {
          return response;
        }));
      case 'PATCH':
        return this.http.patch(url, data).pipe(map((response) => {
          return response;
        }));
      case 'DELETE':
        return this.http.delete(url).pipe(map((response) => {
          return response;
        }));
      default:
        return EMPTY;
    }
  }
}
