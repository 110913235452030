import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardLayoutComponent } from '@layouts/dashboard-layout/dashboard-layout.component';
import { ContentLayoutComponent } from '@layouts/content-layout/content-layout.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table"
import { MatDialogModule } from "@angular/material/dialog"
import { MatSelectModule } from "@angular/material/select"
import { MatRadioModule } from "@angular/material/radio"
import { MatCheckboxModule } from "@angular/material/checkbox"
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';
import { MatBadgeModule } from '@angular/material/badge';
import { MatChipsModule } from '@angular/material/chips';
import { MtxGridModule } from '@ng-matero/extensions/grid';

import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { NavBarComponent } from '@components/nav-bar/nav-bar.component';
import { SidebarComponent } from '@components/sidebar/sidebar.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ConfirmationModalComponent } from '@components/modals/confirmation-modal/confirmation-modal.component';

const THIRD_MODULES = [
  FormsModule,
  ReactiveFormsModule,
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,
  MatButtonModule,
  MatFormFieldModule,
  MatInputModule,
  MatDialogModule,
  MatSelectModule,
  MatRadioModule,
  MatCheckboxModule,
  MatCardModule,
  MatAutocompleteModule,
  MatGridListModule,
  MatTabsModule,
  MatDatepickerModule,
  MatSidenavModule,
  MatListModule,
  MatToolbarModule,
  MatIconModule,
  MatExpansionModule,
  MatMenuModule,
  MatBadgeModule,
  MtxGridModule,
  MatChipsModule,
];

@NgModule({
  exports: [
    CommonModule,
    ...THIRD_MODULES,
    ContentLayoutComponent,
    DashboardLayoutComponent,
    RouterOutlet,
    RouterLink,
    RouterLinkActive,
    NgMultiSelectDropDownModule,
    ConfirmationModalComponent
  ],
  declarations: [
    ContentLayoutComponent,
    DashboardLayoutComponent,
    NavBarComponent,
    SidebarComponent,
    ConfirmationModalComponent
  ],
  imports: [
    CommonModule,
    ...THIRD_MODULES,
    RouterOutlet,
    RouterLink,
    RouterLinkActive,
    RouterModule,
    NgMultiSelectDropDownModule
  ]
})
export class SharedModule { }
