import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { APIResponse } from '@models/api-response-data';
import { AppEventType } from '@models/event.type';
import { ApiService } from '@services/api-service.service';
import { AuthenticationService } from '@services/authentication.service';
import { EventQueueService } from '@services/event-queue.service';
import { ApiConstants } from '@utils/api-constants';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent {
  notificationsCount: any = 0;
  userType!: string;
  navBackground: string = ''
  portalName = '';
  userName = '';
  portalType = 'Admin Panel';
  constructor(
    private apiService: ApiService,
    private router: Router,
    public auth: AuthenticationService,
    private eventQueue: EventQueueService
  ) { }
  navbarClass: any = {
    'root': 'accent'
  }
  ngOnInit(): void {
    this.getUserDetails()
    const userRole: any = this.auth.getUserType();
    this.navBackground = this.navbarClass[userRole]
    this.eventQueue.on(AppEventType.ClickedOnNotification).subscribe(event => this.handleEvent(event.payload));
    this.getNotificationCount();
  }

  doLogout() {
    this.auth.doLogout()
  }

  getNotificationCount() {

  }

  loadNotificationPage() {
    const userType = this.auth.getUserType();
  }

  handleEvent(data: any) {

  }

  getUserDetails() {

  }
}
