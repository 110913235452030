import { RouteInfo } from '@models/route-info-data';

//Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [
  {
    path: '/panel/dashboard',
    title: 'Dashboard',
    icon: 'dashboard',
    class: '',
    badge: '',
    role: 'root',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
  },
  {
    path: '',
    title: 'Accounts',
    icon: 'person',
    class: '',
    badge: '',
    role: 'root',
    badgeClass: '',
    isExternalLink: false,
    submenu: [
      {
        path: '/panel/users/list',
        title: 'Users Account',
        icon: '',
        class: '',
        badge: '',
        role: 'root',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/panel/users/add',
        title: 'Create Account',
        icon: '',
        class: '',
        badge: '',
        role: 'root',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      }
    ],
  },
  {
    path: '',
    title: 'Domains',
    icon: 'web',
    class: '',
    badge: '',
    role: 'root',
    badgeClass: '',
    isExternalLink: false,
    submenu: [
      {
        path: '/panel/domains/tracking-domains',
        title: 'Tracking Domain',
        icon: '',
        class: '',
        badge: '',
        role: 'root',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/panel/domains/redirect-domains',
        title: 'Redirect Domains',
        icon: '',
        class: '',
        badge: '',
        role: 'root',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      }
    ],
  },
  {
    path: '',
    title: 'Plans',
    icon: 'business_center',
    class: '',
    badge: '',
    role: 'root',
    badgeClass: '',
    isExternalLink: false,
    submenu: [
      {
        path: '/panel/plans/list',
        title: 'Plan Lists',
        icon: '',
        class: '',
        badge: '',
        role: 'root',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/panel/plans/add',
        title: 'Create Plan',
        icon: '',
        class: '',
        badge: '',
        role: 'root',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      }
    ],
  },
  {
    path: '',
    title: 'Roles',
    icon: 'security',
    class: '',
    badge: '',
    role: 'root',
    badgeClass: '',
    isExternalLink: false,
    submenu: [
      {
        path: '/panel/roles/list',
        title: 'Roles List',
        icon: '',
        class: '',
        badge: '',
        role: 'root',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/panel/roles/add',
        title: 'Create Roles',
        icon: '',
        class: '',
        badge: '',
        role: 'root',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      }
    ],
  },
  {
    path: '',
    title: 'Preferences',
    icon: 'settings',
    class: '',
    badge: '',
    role: 'root',
    badgeClass: '',
    isExternalLink: false,
    submenu: [
      {
        path: '/panel/preferences/countires',
        title: 'Country List',
        icon: '',
        class: '',
        badge: '',
        role: 'root',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/panel/preferences/currency',
        title: 'Currency List',
        icon: '',
        class: '',
        badge: '',
        role: 'root',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/panel/preferences/city',
        title: 'City List',
        icon: '',
        class: '',
        badge: '',
        role: 'root',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/panel/preferences/state',
        title: 'State List',
        icon: '',
        class: '',
        badge: '',
        role: 'root',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/panel/preferences/timezone',
        title: 'Timezone List',
        icon: '',
        class: '',
        badge: '',
        role: 'root',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/panel/preferences/languages',
        title: 'Languages List',
        icon: '',
        class: '',
        badge: '',
        role: 'root',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/panel/preferences/brands',
        title: 'Brands List',
        icon: '',
        class: '',
        badge: '',
        role: 'root',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/panel/preferences/browsers',
        title: 'Browsers List',
        icon: '',
        class: '',
        badge: '',
        role: 'root',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/panel/preferences/connections',
        title: 'Connection List',
        icon: '',
        class: '',
        badge: '',
        role: 'root',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/panel/preferences/oses',
        title: 'Oses List',
        icon: '',
        class: '',
        badge: '',
        role: 'root',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/panel/preferences/devices',
        title: 'Device List',
        icon: '',
        class: '',
        badge: '',
        role: 'root',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      }
    ],
  },
];
