import { Component } from '@angular/core';
import { ROUTES } from './sidebar-routes.config';
import { ApiService } from '@services/api-service.service';
import { AuthenticationService } from '@services/authentication.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
  subMenuList = [false]
  showSubmenu: boolean = false;
  menuItems: any = [];
  icon = 'store'
  constructor(
    public apiService: ApiService,
    public auth: AuthenticationService
  ) { }

  ngOnInit(): void {
    const userType = this.auth.getUserType();
    this.menuItems = ROUTES.filter((menuItem) => menuItem.role === userType);
  }

  logOut() {
    this.auth.doLogout();
  }
}
